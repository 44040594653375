export default function AboutMe() {
  return (
    <section id="AboutMe" className="about--section">
      <div className="about--section--img">
        <img src="./img/about-me.jpeg" alt="About Me" />
      </div>
      <div className="hero--section--content--box about--section--box">
        <div className="about--section--content">
          <h1 className="about--section--header">About Me</h1>
          <p className="hero--section-description">
          I am an enthusiastic computer science graduate with a love for turning ideas into reality through code. 
          My journey in tech began in high school, where the intrigue of coding in Python and Java ignited my desire 
          to create and innovate.
          </p>
          <p className="hero--section-description">
          I bring to the table a solid foundation in full-stack development, with proficiency in languages like Python, C++, HTML/CSS, and JavaScript. 
          My experiences extend across diverse projects in both industry and academia, where I've honed my skills in team environments while embracing Agile methodologies. 
          Currently, I'm diving into the realm of ReactJS for web development experience.
          </p>
          <p className="hero--section-description">
          Looking ahead, my career goals are two-fold. 
          I aspire to deepen my expertise in full-stack development, allowing me to create comprehensive applications from the ground up.
          Simultaneously, my sights are set on the dynamic and ever-evolving AI/ML sector, where I aim to contribute to the advancement of these cutting-edge technologies. 
          My ambition is not just to use AI applications but to actively engage in research, pushing the boundaries of what's achievable.
          </p>
        </div>
      </div>
    </section>
  );
}
