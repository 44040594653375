import React, { useState } from "react";

export default function ContactMe() {
  const [messageSent, setMessageSent] = useState(null);

  const onSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    // Add your access key obtained from web3forms
    formData.append("access_key", "7c5ad71b-3717-4041-b35c-dd09383dc226");

    // Topic
    const selectedTopic = document.getElementById("choose-topic").value;
    formData.append("Topic", selectedTopic);

    // Full Name
    const firstName = document.getElementById("first-name").value;
    const lastName = document.getElementById("last-name").value;
    const fullName = `${firstName} ${lastName}`;
    formData.append("Full Name", fullName);

    // Email
    const email = document.getElementById("email").value;
    formData.append("Email", email);

    // Phone Number
    const phoneNumber = document.getElementById("phone-number").value;
    formData.append("Phone Number", phoneNumber);

    // Message
    const message = document.getElementById("message").value;
    formData.append("Message", message);


    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    const res = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      body: json
    }).then((res) => res.json());

    if (res.success) {
      setMessageSent("Message Sent Successfully");
    } else {
      setMessageSent("Message Sent Failed");
    }
  };

  return (
    <section id="Contact" className="contact--section">
      <div>
        <p className="sub--title">Get In Touch</p>
        <h2 className="contact--header">Contact Me</h2>
        <p className="text-lg">
          I will get back to you as soon as I can!
        </p>
      </div>
      <form className="contact--form--container" onSubmit={onSubmit}>
        <div className="container">
          <label htmlFor="first-name" className="contact--label">
            <span className="text-md">First Name</span>
            <input
              type="text"
              className="contact--input text-md"
              name="first-name"
              id="first-name"
              required
            />
          </label>
          <label htmlFor="last-name" className="contact--label">
            <span className="text-md">Last Name</span>
            <input
              type="text"
              className="contact--input text-md"
              name="last-name"
              id="last-name"
              required
            />
          </label>
          <label htmlFor="email" className="contact--label">
            <span className="text-md">Email</span>
            <input
              type="email"
              className="contact--input text-md"
              name="email"
              id="email"
              required
            />
          </label>
          <label htmlFor="phone-number" className="contact--label">
            <span className="text-md">Phone Number</span>
            <input
              type="number"
              className="contact--input text-md"
              name="phone-number"
              id="phone-number"
              required
            />
          </label>
        </div>
        <label htmlFor="choose-topic" className="contact--label">
          <span className="text-md">Choose a topic</span>
          <select id="choose-topic" className="contact--input text-md">
            <option>Select One...</option>
            <option>General Inquiry</option>
            <option>Employment Opportunity Inquiry</option>
            <option>Website Feedback and Suggestions</option>
            <option>Other</option>
          </select>
        </label>
        <label htmlFor="message" className="contact--label">
          <span className="text-md">Message</span>
          <textarea
            className="contact--input text-md"
            id="message"
            rows="8"
            placeholder="Type your message..."
          />
        </label>
        <label htmlFor="checkbox" className="checkbox--label">
          <input type="checkbox" required name="checkbox" id="checkbox" />
          <span className="text-sm">I certify the above information is correct.</span>
        </label>
        <div>
        <p className={`message ${messageSent === "Message Sent Successfully!" ? "message-success" : "message-fail"}`} style={{ color: "white" }}>{messageSent}</p>
          {!messageSent && <button type="submit" className="btn btn-primary contact--form--btn">
            Submit
          </button>}
        </div>
      </form>
    </section>
  );
}
