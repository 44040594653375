import "../../App.css";

let timelineElements = [
  {
    id: 1,
    title: "Eastern Technical High School",
    location: "Essex, Maryland",
    description:
      "Information Technology: Networking Pathway (Cisco) Program",
    buttonText: "More Details",
    date: "August 2016 - May 2020",
    icon: "school",
    modalContent: (
      <div className="modal-body">
        <b><u>GPA (unweighted, weighted):</u></b> 3.84/4.0, 5.41/6.0 <br/><br/>
        <b><u>Major:</u></b> Information Technology: Networking Pathway (Cisco) Program<br/><br/>
        <b><u>Honors:</u></b> Honor Roll, President’s Excellence Award, Merit Scholastic Award<br/><br/>
        Eastern Technical High School is a Baltimore County Public School that offers specialized magnet programs. I was in the Cisco Networking Pathway program, where I was introduced to networking concepts such as router and switch configurations, routing protocols (OSPF, EIGRP, RIP), VLANs, subnetting, port security, etc. These studies lead to my Cisco Certified Entry Network Technician (CCENT) certification. I also participated in AFA Cyber Patriot Competitions for 2 years, where I worked in and led teams for system hardening and configuring operating systems such as Windows 10/Server 2016 and Cisco iOS.
      </div>
    ),
  },
  {
    id: 2,
    title: "Enrolled at University of Maryland, Baltimore County (UMBC)",
    location: "Baltimore, Maryland",
    description:
      "Center for Women in Technology (CWIT) Cyber Scholars Program",
    buttonText: "More Details",
    date: "September 2020",
    icon: "school",
    modalContent: (
      <div className="modal-body">
        <b><u>Major:</u></b> Computer Science<br/><br/>
        <b><i>Cyber Scholars Program (merit-based):</i></b><br/>A scholars program dedicated to preparing the next generation of cybersecurity professionals in an increasingly digital age, with a focus on increasing the participation of women and other underrepresented groups in this fast-growing field.<br/><br/>
        <i><b>Center for Women in Technology (CWIT):</b></i> <br/>CWIT is an organization that enables success for all women and other underrepresented groups in technology fields by cultivating a supportive, diverse, community in computing and engineering at UMBC, creating opportunities for girls to deepen their interest in computing and engineering with K-12 outreach programs, examining and addressing gender climate through research on the undergraduate educational experience, and so much more. As an Allies of CWIT Lead, I spent my sophomore year planning and executing lessons for incoming male-identifying CWIT members, teaching about topics surrounding diversity, equity, and inclusion in both school and the workplace.<br/><br/>
      </div>
    ),
  },
  {
    id: 3,
    title: "Desktop Support Student Technician",
    location: "University of Maryland, Baltimore County (UMBC)",
    description:
      "Work within the university's Division of Information Technology (DoIT) to support campus-wide technology, image Macs and PCs, provide input in design, imaging, and deployment of campus computer labs and other learning spaces, as well as in-person and remote support for UMBC users.",
    date: "July 2021 - December 2023",
    icon: "work",
  },
  {
    id: 4,
    title: "Software Engineer Intern",
    location: "Northrop Grumman",
    description:
      "Utilized C++ and Python for optimizing Synthetic Aperture Radar simulations to run in a Unix environment. Worked in a team environment using Atlassian products such as Agile, BitBucket, Confluence, and JIRA to upgrade and optimize existing software for practical use.",
    date: "May 2022 - July 2022",
    icon: "work",
  },
  {
    id: 5,
    title: "Software Engineer Intern",
    location: "Northrop Grumman",
    description:
      "Developed C++ message classes enabling communication between a multitude of systems on the EA-18G Growler aircraft, facilitating the exchange of diverse message types. Collaborated closely with the program team and senior engineers in conducting comprehensive code reviews, gaining invaluable exposure to efficient coding methodologies and Agile development practices.",
    date: "June 2023 - August 2023",
    icon: "work",
  },
  {
    id: 6,
    title: "Graduated from University of Maryland, Baltimore County (UMBC)",
    location: "Baltimore, Maryland",
    description:
      "B.S. Computer Science, Artificial Intelligence and Machine Learning Track",
    buttonText: "More Details",
    date: "December 2023",
    icon: "school",
    modalContent: (
      <div className="modal-body">
        <b><u>Degree:</u></b> B.S. Computer Science - Artificial Intelligence and Machine Learning Track <br/><br/>
        <b><u>Honors:</u></b> Magna Cum Laude<br/><br/>
        <b><u>GPA:</u></b> 3.84/4.0<br/><br/>
        <b><i>Relevant Coursework:</i></b><br/>
        <li>Data Structures and Algorithms</li>
        <li>Database Management Systems and Intro to Data Science</li>
        <li>Computer Organization and Assembly</li>
        <li>Discrete Structures and Linear Algebra</li>
        <li>Computer Architecture and Principles of Operating Systems</li>
        <li>Artificial Intelligence, Machine Learning, Natural Language Processing</li>

        <br/><br/>
      </div>
    ),
  },
  {
    id: 7,
    title: "Associate Pathways Software Engineer",
    location: "Northrop Grumman",
    description:
      "",
    date: "February 2024 - Present",
    icon: "work",
  },
];

export default timelineElements;
