
const handleResumeClick = () => {
  window.open('./img/Resume - Franco Maxey.pdf', '_blank');
};

export default function HeroSection() {
  return (
    <section id="heroSection" className="hero--section">
      <div className="hero--section--content--box">
        <div className="hero--section--content">
          <p className="section--title">Hi, I'm Franco Maxey!</p>
          <h1 className="hero--section--title">
            <span className="hero--section-title--color">Full Stack</span>{" "}
            <br />
            Developer
          </h1>
          <p className="hero--section-description">
            Welcome to my digital portfolio!
          </p>
          <button className="btn btn-primary" onClick={handleResumeClick}>View Resume</button>
        </div>
      </div>
      <div className="hero--section--img">
        <img src="./img/hero_img.jpg" alt="Hero Section"/>
      </div>
    </section>
  );
}
