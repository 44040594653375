import "../../App.css";
import { ReactComponent as WorkIcon } from "./work.svg";
import { ReactComponent as SchoolIcon } from "./school.svg";
import { useState } from "react";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import timelineElements from "./timelineElements";

export default function Experience() {
  const [modalShow, setModalShow] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [modalTitle, setModalTitle] = useState("");
  
  const openModal = (content,title) => {
    setModalContent(content);
    setModalShow(true);
    setModalTitle(title);
  };

  const closeModal = () => {
    setModalShow(false);
  };

  return (
    <>
      <section id="Experience" className="experience--section">
        <div>
          <h1 className="title">My Experience</h1>
          <VerticalTimeline>
            {timelineElements.map((element) => {
              let isWorkIcon = element.icon === "work";
              let showButton =
                element.buttonText !== undefined &&
                element.buttonText !== null &&
                element.buttonText !== "";

              return (
                <VerticalTimelineElement
                  key={element.id}
                  date={element.date}
                  dateClassName="date"
                  iconStyle={isWorkIcon ? { background: "#06D6A0" } : { background: "#FCAA67" }}
                  icon={isWorkIcon ? <WorkIcon /> : <SchoolIcon />}
                >
                  <h3 className="vertical-timeline-element-title">{element.title}</h3>
                  <h5 className="vertical-timeline-element-subtitle">{element.location}</h5>
                  <p id="description">{element.description}</p>
                  {showButton && (
                    <Button
                    style={{ backgroundColor: isWorkIcon ? "#06D6A0" : "#FCAA67", borderColor: isWorkIcon ? "#06D6A0" : "#FCAA67" }} // Set the background color dynamically based on isWorkIcon
                    onClick={() => openModal(element.modalContent,element.title)}
                    >
                      {element.buttonText}
                    </Button>
                  )}
                </VerticalTimelineElement>
              );
            })}
          </VerticalTimeline>
        </div>
      </section>
      <Modal
        size="lg"
        show={modalShow}
        onHide={closeModal}
        aria-labelledby="example-modal-sizes-title-sm"
        centered
      >
        <Modal.Header>
          <Modal.Title>
            {modalTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {modalContent}
        </Modal.Body>
        <Modal.Footer style={{textAlign: "center"}}>
        <Button variant="secondary" onClick={closeModal} className="close-button">Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
